import React from "react"
import Layout from "components/layout"
import ViewAward from "components/ViewAward"
import usePageData from 'hooks/queryAwards';

const AwardPage = props => {
  const { pageContext } = props;
  const pageData = usePageData();

  return (
    <Layout pageContext={pageContext}>
      <ViewAward pageData={pageData} />
    </Layout>
  )
}

export default AwardPage
